import React, { useEffect } from 'react'
import styles from './Filter.module.scss'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { getFilters } from '../../api/get'
import { useFormik } from 'formik'
import { updateFilter } from '../../redux/actions/leads.action'
import CircularProgress from '@material-ui/core/CircularProgress'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { ICountry, IFilterValues, ILeadStatus, IUser } from '../../globalTypes'
import { RootState } from '../../redux/reducers/root.reducer'
import FieldSearch from '../FieldSearch'
import StatusColor from '../StatusColor'

interface IFilterMoreProps {
  onClose: () => void
}

const FilterMore: React.FC<IFilterMoreProps> = ({ onClose }) => {
  const dispatch = useDispatch()

  const filter = useSelector((state: RootState) => state.leads.filter)
  const [countries, setCountries] = React.useState<Array<ICountry>>([])
  const [statuses, setStatuses] = React.useState<Array<ILeadStatus>>([])
  const [users, setUsers] = React.useState<Array<IUser>>([])

  const [loading, setLoading] = React.useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    getFilters(['user', 'country', 'status']).then((data) => {
      setLoading(false)
      setStatuses(data.status)
      setCountries(data.country)
      setUsers(data.user)
    })
  }, [])

  const initialValues: IFilterValues = {
    search: filter.search || "",
    company: filter.company || '',
    country: filter.country || null,
    status: filter.status ? filter.status.id : '',
    user: filter.user ? filter.user.id : '',
    city: filter.city || '',
    phone: filter.phone || '',
    email: filter.email || '',
  }

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      onClose()
      dispatch(
        updateFilter({
          ...values,
          user: users.find((item) => item.id === values.user) || null,
          status: statuses.find((item) => item.id === values.status) || null,
        })
      )
    },
  })

  const handleInputChange = (event: React.ChangeEvent<any>): void => {
    formik.handleChange(event)
  }

  const handleFieldSearchChange = (name: string, value: string): void => {
    formik.handleChange({ target: { name, value } })
  }

  const { values } = formik
  return (
    <form noValidate className={styles.more} onSubmit={formik.handleSubmit}>
      {loading ? (
        <div className={styles.loader_wrapper}>
          <CircularProgress />
        </div>
      ) : (
        <>

          <div className={styles.field} >
            <TextField
              size="small"
              name="search"
              label="Search"
              value={values.search}
              variant="outlined"
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.field}>
            <FieldSearch
              onChange={handleFieldSearchChange}
              name="company"
              label="Company"
              value={values.company}
            />
          </div>

          <div className={styles.field}>
            <FieldSearch
              onChange={handleFieldSearchChange}
              name="city"
              label="City"
              value={values.city}
            />
          </div>

          <div className={styles.field}>
            <FieldSearch
              onChange={handleFieldSearchChange}
              name="phone"
              label="Phone"
              value={values.phone}
            />
          </div>

          <div className={styles.field}>
            <FieldSearch
              onChange={handleFieldSearchChange}
              name="email"
              label="Email"
              value={values.email}
            />
          </div>

          <div className={styles.field}>
            <Autocomplete
              options={countries}
              clearOnBlur
              getOptionLabel={(option: ICountry) => option.name}
              value={values.country}
              onChange={(event: any, value: ICountry | null) => {
                formik.setValues({ ...values, country: value })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </div>

          <div className={styles.field}>
            <FormControl variant="outlined" size="small">
              <InputLabel>Created by</InputLabel>
              <Select
                name="user"
                value={values.user}
                MenuProps={{ disablePortal: true }}
                onChange={handleInputChange}
              >
                <MenuItem value="">None</MenuItem>
                {users.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.last_name} {item.first_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className={styles.field}>
            <FormControl variant="outlined" size="small">
              <InputLabel>Lead status</InputLabel>
              <Select
                id="status-label"
                name="status"
                value={values.status}
                MenuProps={{ disablePortal: true }}
                onChange={handleInputChange}
              >
                <MenuItem value="">None</MenuItem>
                {statuses.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <span>{item.name}</span>
                    <StatusColor color={item.color} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className={styles.field} style={{ marginBottom: 0 }}>
            <Button type="submit" variant="contained" color="primary">
              apply
            </Button>
          </div>
        </>
      )}
    </form>
  )
}

export default FilterMore

import * as yup from 'yup'

export const LeadModalFormSchema = yup.object().shape({
  emails: yup.array(
    yup.string().email('Invalid email').required('Email is required')
  ),
  phones: yup.array(
    yup.object().shape({
      country_code: yup.object().nullable(),
      phone: yup.object().when('country_code', (field: object | null) => {
        if (field) {
          return yup.string().min(5).nullable().required('invalid phone')
        } else return yup.string().min(5).nullable()
      }),
    })
  ),
})

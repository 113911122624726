export function utcToLocalDate(data: string) {
  const date = new Date(data)
  const isoDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString()
  return datePrettier(isoDate)
}

export function datePrettier(str: string) {
  const date = new Date(str)

  const timeString = date.toLocaleTimeString('en-GB')
  const dateString = date.toLocaleDateString().replaceAll('/', '-')
  return `${dateString}, ${timeString}`
}

export function parseString(
  str: string,
  firstSeparator: string,
  secondSeparator: string
) {
  return str.split(firstSeparator)[1].split(secondSeparator)[0]
}

import React from 'react'
import styles from './LeadTable.module.scss'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/root.reducer'
import { ArrowSvg } from '../Icons'
import {
  selectAllLeadsAction,
  unselectAllLeads,
  updateFilterSorting,
} from '../../redux/actions/leads.action'
import { Checkbox } from '@material-ui/core'
import { ILead } from '../../globalTypes'

interface ILeadTableHeaderProps {}

const headTitles = [
  { value: 'company', label: 'company' },
  { value: 'country', label: 'country' },
  { value: 'pic', label: 'PIC' },
  { value: 'phone', label: 'phone' },
  { value: 'email', label: 'email' },
]
const disabledTitles = ['phone', 'email']

const LeadTableHeader: React.FC<ILeadTableHeaderProps> = () => {
  const dispatch = useDispatch()
  const ordering: string | null = useSelector(
    (state: RootState) => state.leads.ordering
  )

  const combine_mode: { is_active: boolean } = useSelector(
    (state: RootState) => state.leads.combine_mode
  )

  const leads: Array<ILead> = useSelector(
    (state: RootState) => state.leads.leads_list
  )

  const isAllSelected = leads.every((item: ILead) => item.is_selected === true)

  function handleSortButtonClick(name: string) {
    const sortString = ordering === name ? `-${name}` : name
    dispatch(updateFilterSorting(sortString))
  }

  function handleCheckboxClick() {
    if (isAllSelected) {
      dispatch(unselectAllLeads())
    } else {
      dispatch(selectAllLeadsAction())
    }
  }

  return (
    <div className={styles.thead}>
      <div className={cn(styles.row, 'container')}>
        {headTitles.map((item, key) => (
          <div key={key} className={styles.field}>
            <div
              className={cn(styles.sort_button, {
                [styles.non_events]: disabledTitles.includes(item.value),
              })}
              onClick={() => handleSortButtonClick(item.value)}
            >
              {item.label}
              {ordering !== null && ordering.includes(item.value) && (
                <ArrowSvg fill="#ffffff" rotate={ordering.includes('-')} />
              )}
            </div>
          </div>
        ))}
        <div className={styles.last_field}>
          <Checkbox
            disabled={combine_mode.is_active}
            size="small"
            checked={isAllSelected}
            onClick={handleCheckboxClick}
            color="secondary"
          />
        </div>
      </div>
    </div>
  )
}

export default LeadTableHeader

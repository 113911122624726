import React from 'react'
import styles from './InfoModal.module.scss'
import Modal from '@material-ui/core/Modal'
import { useDispatch } from 'react-redux'
import { setIsUiModalOpen } from '../../redux/actions/ui.action'
import { Button } from '@material-ui/core'

interface IInfoModalProps {
  isOpen?: boolean
}

const InfoModal: React.FC<IInfoModalProps> = ({ isOpen = true }) => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(setIsUiModalOpen('info', false))
  }

  return (
    <Modal
      onClose={handleClose}
      open={isOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={styles.block}>
        <p>
          Response system предназначена для хранения списка компаний.
          Необходимым полем при создании новой записи является поле Email. Для
          каждой учетной записи можно создавать индивидуальные списки, куда
          можно копировать компании из основного списка ALL.
        </p>

        <p>
          Для каждой новой записи отмечается ее создатель в поле Create by. По
          этому полю можно фильтровать записи. Каждая запись может иметь
          несколько телефонных номеров и несколько емайл адресов. Любой
          индивидуальный список можно экспортировать в формат EXCEL.
        </p>

        <p>
          В списках можно сортировать записи по полям Company, Country, PIC
          (Person-in-Charge ). В списках можно фильтровать записи по полям
          Company, City, Phone, Email, Country, Create by, Lead Status. При
          вводе текста в поля фильтра всплывают подсказки из числа записей,
          находящихся в списке, позволяющие правильно выбрать критерий фильтра.
        </p>

        <p>
          Индивидуальные списки можно переименовывать или удалять через меню с
          правой стороны заголовка списка. При удалении записи из
          индивидуального списка, запись не удаляется из основного списка ALL.
        </p>

        <p>
          Отметка Lead Status предназначена для отображения статуса записи,
          например, “My Company” или “Nonexistent Company”. Перечнем Lead Status
          можно управлять. Каждый Lead Status имеет цветную маркировку. В
          таблице, при наведении курсора на цветную метку всплывает подсказка с
          названием Lead Status. Каждая запись может иметь только одну отметку
          Lead Status.
        </p>

        <p>
          Создание записи, с существующим названием компании, запрещено.
          Создание записи, с существующим Email адресом, запрещено. Поле Phone
          имеет формат записи: вначале символ +, после расположены, без
          пробелов, не более 15 цифр, например +12026838353.
        </p>

        <p>
          При объединении дублированных записей, необходимо отметить чекбоксы
          записей, которые должны быть совмещены и нажать кнопку Combine. После
          необходимо нажать кнопку Main рядом с главной записью, в которую будут
          перемещена информация с дублей. После этого дубли будут удалены из
          списка. Для отмены объединения необходимо нажать кнопку Cancel.
        </p>

        <p>
          Критерии фильтра можно удалять нажатием на кнопку, соответствующую
          критерию, в строке критериев фильтра Все пользователи имеют равные
          права при просмотре и редактировании списка ALL.
        </p>

        <div className={styles.footer}>
          <Button onClick={handleClose} color="primary" variant="contained">
            Okay
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default InfoModal

import {
  DELETE_STATUS,
  SET_CURRENT_ON_EDIT,
  UPDATE_STATUS,
  UPDATE_STATUSES_LIST,
} from '../types'
import { ILeadStatus, IServerError } from '../../globalTypes'
import { Dispatch } from 'react'
import { getFilters } from '../../api/get'
import { postNewStatusRequest } from '../../api/post'
import { toast } from 'react-toastify'
import { deleteStatusRequest, putStatusRequest } from '../../api/status.api'

export const getStatusesList = () => (dispatch: Dispatch<any>) => {
  getFilters(['status']).then(({ status }) => {
    dispatch(updateStatusesList(status))
  })
}

export const postNewStatus = (status: ILeadStatus) => (
  dispatch: any,
  getState: any
) => {
  const statuses = getState().statusCreator.statuses
  postNewStatusRequest(status).then((data) => {
    if (data.ok) {
      dispatch(updateStatusesList([...statuses, data]))
      dispatch(setCurrentOnEdit(null))
      toast.success(`Status '${data.name}' was created!`)
    } else {
      data.errors.forEach((error: IServerError) => toast.error(error.message))
    }
  })
}

export const updateStatus = (status: ILeadStatus) => (dispatch: any) => {
  putStatusRequest(status).then((data) => {
    if (data.ok) {
      dispatch(updateStatusAction(data))
      dispatch(setCurrentOnEdit(null))
      toast.success(`Status '${data.name}' was updated!`)
    } else {
      data.errors.forEach((error: IServerError) => toast.error(error.message))
    }
  })
}

export const deleteStatus = (id: number) => (dispatch: any) => {
  deleteStatusRequest(id).then((data) => {
    if (data.ok) {
      dispatch(deleteStatusAction(id))
      dispatch(setCurrentOnEdit(null))
      toast.success(`Status was deleted!`)
    } else {
      data.errors.forEach((error: IServerError) => toast.error(error.message))
    }
  })
}

// actions
export const setCurrentOnEdit = (data: ILeadStatus | null | 'new status') => ({
  type: SET_CURRENT_ON_EDIT,
  payload: data,
})

export const updateStatusesList = (data: Array<ILeadStatus>) => ({
  type: UPDATE_STATUSES_LIST,
  payload: data,
})

export const updateStatusAction = (data: ILeadStatus) => ({
  type: UPDATE_STATUS,
  payload: data,
})

export const deleteStatusAction = (id: number) => ({
  type: DELETE_STATUS,
  payload: id,
})

import { Switch } from 'react-router-dom'
import Login from './Login'
import LeadsPage from './LeadsPage'
import PrivateRoute from '../components/PrivateRoute'
import PublicRoute from '../components/PublicRoute'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { addToken } from '../redux/actions/user.action'

function Root() {
  const dispatch = useDispatch()

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('access_token') || 'null')
    if (token) {
      dispatch(
        addToken({
          access_token: token,
          is_auth: true,
        })
      )
    }
  }, [dispatch])

  return (
    <>
      <Switch>
        <PublicRoute exact path="/" component={Login} />
        <PrivateRoute exact path="/crm-leads" component={LeadsPage} />
        <PrivateRoute
          exact={false}
          path="/crm-leads/:custom_list"
          component={LeadsPage}
        />
      </Switch>
    </>
  )
}

export default Root

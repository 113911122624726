import React from 'react'
import styles from './CustomListsNavigation.module.scss'
import { NavLink, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/root.reducer'
import { ICustomList, IParams } from '../../globalTypes'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { deleteCustomListAction } from '../../redux/actions/leads.action'
import { ArrowSvg } from '../Icons'
import cn from 'classnames'

function CustomListsNavigation() {
  const dispatch = useDispatch()
  const lists: Array<ICustomList> = useSelector(
    (state: RootState) => state.leads.custom_lists
  )

  const { custom_list }: IParams = useParams()

  const pagination = useSelector((state: RootState) => state.leads.pagination)
  const loading = useSelector((state: RootState) => state.leads.loading)

  const leadsCounter = loading
    ? null
    : pagination.count
    ? `(${pagination.count})`
    : null

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  function handleClick(event: React.SyntheticEvent<HTMLButtonElement>) {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleDelete() {
    if (anchorEl && anchorEl.dataset.id) {
      const id: number = parseInt(anchorEl.dataset.id)
      dispatch(deleteCustomListAction(id))
      setAnchorEl(null)
    }
  }

  return (
    <div className={styles.list}>
      <div className={styles.link_wrapper}>
        <NavLink
          to={`/crm-leads/`}
          exact
          className={cn(styles.item, styles.item_default)}
          activeClassName={styles.active}
        >
          ALL {custom_list === undefined && leadsCounter}
        </NavLink>
      </div>

      {lists.map(({ name, id }) => {
        return (
          <div key={id} className={styles.link_wrapper}>
            <div
              className={cn(styles.item, {
                [styles.active]: custom_list && +custom_list === id,
              })}
            >
              <NavLink to={`/crm-leads/${id}`} className={styles.item_text}>
                {name} {custom_list === id.toString() && leadsCounter}
              </NavLink>
              <button
                data-id={id}
                className={styles.button}
                onClick={handleClick}
              >
                <ArrowSvg fill="#fff" />
              </button>
            </div>
          </div>
        )
      })}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled>Rename</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled>Rename</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </div>
  )
}

export default CustomListsNavigation

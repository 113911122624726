import React from 'react'
import styles from './LeadTable.module.scss'
import { ILead } from '../../globalTypes'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/root.reducer'
import LeadTableHeader from './LeadTableHeader'
import CircularProgress from '@material-ui/core/CircularProgress'
import LeadTableBody from './LeadTableBody'

interface ILeadTableProps {}

const LeadTable: React.FC<ILeadTableProps> = () => {
  const loading = useSelector((state: RootState) => state.leads.loading)

  const leads: Array<ILead> = useSelector(
    (state: RootState) => state.leads.leads_list
  )

  return (
    <div className={styles.table}>
      {loading && (
        <div className={styles.spinner_wrapper}>
          <CircularProgress />
        </div>
      )}
      <LeadTableHeader />
      {!leads.length ? (
        <div className={styles.no_content}>{!loading && 'Not found'}</div>
      ) : (
        <LeadTableBody />
      )}
    </div>
  )
}

export default LeadTable

import React from 'react'
import styles from './SelectItem.module.scss'
import cn from 'classnames'

interface ISelectItemProps {
  value: number
  onClick: (value: number) => void
  isActive: boolean
}

const SelectItem: React.FC<ISelectItemProps> = ({
  children,
  value,
  onClick,
  isActive,
}) => {
  return (
    <div
      onClick={() => onClick(value)}
      className={cn(styles.block, { [styles.active]: isActive })}
    >
      {children}
    </div>
  )
}

export default SelectItem

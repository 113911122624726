import React from 'react'
import styles from './UserMenu.module.scss'
import { Button } from '@material-ui/core'
import { userLogout } from '../../redux/actions/user.action'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowSvg, ExitSvg, SettingsSvg } from '../Icons'
import { RootState } from '../../redux/reducers/root.reducer'
import { setIsUiModalOpen } from '../../redux/actions/ui.action'
import useClickOutside from '../../hooks/useClickOutside'

interface IUserMenuProps {}

const UserMenu: React.FC<IUserMenuProps> = () => {
  const dispatch = useDispatch()

  const userInfo = useSelector((state: RootState) => state.user.info)

  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const ref = useClickOutside(() => setIsOpen(false))
  function handleExitClick() {
    dispatch(userLogout())
  }

  function handleSettingsClick() {
    dispatch(setIsUiModalOpen('status_editor', true))
  }

  return (
    <div className={styles.block} ref={ref}>
      <Button
        endIcon={<ArrowSvg rotate={isOpen} />}
        onClick={() => setIsOpen(!isOpen)}
        style={{ textTransform: 'initial' }}
      >
        {userInfo.email}
      </Button>

      {isOpen && (
        <div className={styles.more}>
          <Button
            color="primary"
            onClick={handleSettingsClick}
            startIcon={<SettingsSvg />}
          >
            Statuses
          </Button>
          <Button
            color="primary"
            onClick={handleExitClick}
            startIcon={<ExitSvg fill={'var(--regular-color)'} />}
          >
            Logout
          </Button>
        </div>
      )}
    </div>
  )
}

export default UserMenu

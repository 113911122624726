import { API_BASE, API_VERSION } from './constants'

const patchAuthRequest = async (url: string, data: object) => {
  const token = JSON.parse(localStorage.getItem('access_token') || 'null')

  const response = await fetch(`${API_BASE}${API_VERSION}crm/${url}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })

  const json = await response.json()
  json.ok = response.ok

  return await json
}

export const moveLeadToCustom = (id: number, leads: Array<number>) => {
  return patchAuthRequest(`custom-lists/${id}/`, { leads: leads })
}

import React from 'react'
import styles from './FilterIndicator.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/root.reducer'
import { CloseSvg } from '../Icons'
import { deleteFilterItem } from '../../redux/actions/leads.action'

interface IFilterIndicatorProps {}

const FilterIndicator: React.FC<IFilterIndicatorProps> = () => {
  const dispatch = useDispatch()

  const filter = useSelector((state: RootState) => state.leads.filter)

  const items = Object.keys(filter)
    .filter((item) => filter[item])
    .map((item) => ({
      name: item,
      value:
        item === 'user'
          ? `${filter[item].first_name} ${filter[item].last_name}`
          : item === 'status' || item === 'country'
          ? filter[item].name
          : filter[item],
    }))

  function handleClick(name: string) {
    dispatch(deleteFilterItem(name))
  }

  return (
    <div className={styles.block}>
      <div className={`container ${styles.wrapper}`}>
        {items.map((item, idx) => (
          <div
            key={idx}
            className={styles.item}
            onClick={() => handleClick(item.name)}
          >
            {item.value}
            <CloseSvg />
          </div>
        ))}
      </div>
    </div>
  )
}

export default FilterIndicator

import { SET_IS_LEAD_MODAL_OPEN, START_EDIT_LEAD } from '../types'

const initialState = {
  is_open: false,
  form: null,
  lead_id: null,
}
export default function leadModalReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_IS_LEAD_MODAL_OPEN:
      return {
        ...state,
        is_open: action.payload,
        form: null,
        lead_id: null,
      }

    case START_EDIT_LEAD:
      return {
        ...state,
        is_open: true,
        form: action.payload.lead,
        lead_id: action.payload.id,
      }

    default:
      return state
  }
}

import React, { useEffect, useState } from 'react'
import styles from './StatusMultiEditor.module.scss'
import { ArrowSvg } from '../Icons'
import { getFilters } from '../../api/get'
import { ILead, ILeadStatus } from '../../globalTypes'
import StatusColor from '../StatusColor'
import cn from 'classnames'
import { Button, CircularProgress } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/root.reducer'
import { setStatusesThunk } from '../../redux/actions/leads.action'

interface IStatusMultiEditorProps {}

const StatusMultiEditor: React.FC<IStatusMultiEditorProps> = () => {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)
  const [statusList, setStatusList] = useState<Array<ILeadStatus>>([])
  const [activeStatus, setActiveStatus] = useState<
    ILeadStatus | null | 'clear'
  >(null)
  const [loading, setLoading] = useState<boolean>(false)

  const selectedLeads: Array<number> = useSelector(
    (state: RootState) => state.leads.leads_list
  )
    .filter((item: ILead) => item.is_selected)
    .map((item: ILead) => item.id)

  function handleStatusClick(event: React.SyntheticEvent<HTMLButtonElement>) {
    const { name } = event.currentTarget
    if (name === 'clear') {
      setActiveStatus('clear')
    } else {
      const id = parseInt(name)
      setActiveStatus(statusList.find((item) => item.id === id) || null)
    }
  }

  function handleApply() {
    setIsOpen(false)
    const result = activeStatus === 'clear' ? null : activeStatus
    dispatch(setStatusesThunk(result, selectedLeads))
    setActiveStatus(null)
  }

  useEffect(() => {
    if (isOpen) {
      setLoading(true)
      getFilters(['status']).then(({ status }) => {
        setLoading(false)
        setStatusList(status)
      })
    }
  }, [isOpen])
  const isApplyDisabled = !selectedLeads.length
  return (
    <div className={styles.block}>
      <div className={styles.select} onClick={() => setIsOpen(!isOpen)}>
        <span className={styles.select_text}>Change Statuses</span>
        <ArrowSvg rotate={isOpen} />
      </div>

      {isOpen && (
        <div className={styles.statuses}>
          {loading ? (
            <CircularProgress />
          ) : (
            statusList.map(({ name, id, color }) => (
              <button
                onClick={handleStatusClick}
                name={id?.toString()}
                className={cn(styles.status_button, {
                  [styles.active]:
                    activeStatus !== 'clear' && id === activeStatus?.id,
                })}
                key={id}
              >
                <span>{name}</span>
                <StatusColor
                  color={color}
                  style={{ position: 'static', transform: 'unset' }}
                />
              </button>
            ))
          )}

          <button
            onClick={handleStatusClick}
            name="clear"
            className={cn(styles.status_button, {
              [styles.active]: activeStatus === 'clear',
            })}
          >
            <span>Clear status</span>
          </button>

          <Button
            onClick={handleApply}
            disabled={isApplyDisabled}
            color="primary"
            size="small"
            variant="outlined"
          >
            Apply
          </Button>
        </div>
      )}
    </div>
  )
}

export default StatusMultiEditor

import React from 'react'
import styles from './Header.module.scss'
import CustomListsNavigation from '../CustomListsNavigation/CustomListsNavigation'
import UserMenu from '../UserMenu/UserMenu'
import cn from 'classnames'

interface IHeaderProps {}

const Header: React.FC<IHeaderProps> = () => {
  return (
    <header className={styles.header}>
      <div className={cn('container', styles.wrapper)}>
        <CustomListsNavigation />
        <UserMenu />
      </div>
    </header>
  )
}

export default Header

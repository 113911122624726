import React from 'react'
import styles from './LeadTable.module.scss'
import { ILead } from '../../globalTypes'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/root.reducer'
import LeadTableItem from './LeadTableItem'
import { loadMoreLeads } from '../../redux/actions/leads.action'
import CircularProgress from '@material-ui/core/CircularProgress'

interface ILeadTableBodyProps {}

const LeadTableBody: React.FC<ILeadTableBodyProps> = () => {
  const dispatch = useDispatch()

  const leads: Array<ILead> = useSelector(
    (state: RootState) => state.leads.leads_list
  )

  const pagination: any = useSelector(
    (state: RootState) => state.leads.pagination
  )

  const combine_mode: { is_active: boolean } = useSelector(
    (state: RootState) => state.leads.combine_mode
  )

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (pagination.next && !combine_mode.is_active) {
      if (
        event.currentTarget.scrollTop + 900 >=
          event.currentTarget.scrollHeight &&
        !pagination.is_loading
      ) {
        dispatch(loadMoreLeads(pagination.next))
      } else {
        return null
      }
    }
  }

  return (
    <div className={styles.tbody} onScroll={handleScroll}>
      {(combine_mode.is_active
        ? leads.filter((item) => item.is_selected)
        : leads
      ).map((item: ILead) => (
        <LeadTableItem
          key={item.id}
          item={item}
          isCombineActive={combine_mode.is_active}
        />
      ))}
      {pagination.next && !combine_mode.is_active && (
        <div className={styles.scroll_loader}>
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

export default LeadTableBody

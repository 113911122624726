import {
  CREATE_NEW_LEAD,
  DECREMENT_LEADS_COUNT,
  DELETE_CUSTOM_LIST,
  DELETE_FILTER_ITEM,
  DELETE_LEAD,
  DELETE_LEADS,
  INCREMENT_LEADS_COUNT,
  LOAD_MORE_LEADS,
  SAVE_CUSTOM_LIST,
  SELECT_ALL_LEADS,
  SET_IS_LEAD_SELECTED,
  SET_IS_LOADING,
  SET_STATUSES,
  TOGGLE_COMBINE_MODE,
  UNSELECT_ALL_LEADS,
  UPDATE_CUSTOM_LIST,
  UPDATE_FILTER,
  UPDATE_FILTER_SORTING,
  UPDATE_LEAD_DATA,
  UPDATE_LEADS_LIST,
  UPDATE_PAGINATION,
} from '../types'
import { getCustomLists, getLeadsList, getMoreLeadsList } from '../../api/get'
import {
  ICustomList,
  IFilterValues,
  ILeadFormValues,
  ILeadStatus,
  IServerError,
} from '../../globalTypes'
import { postCustomList, postSetStatuses } from '../../api/post'
import { objToQueryConverter } from '../../api/api.helper'
import { moveLeadToCustom } from '../../api/patch'
import {
  deleteCustomList,
  deleteCustomListLeads,
  deleteLeads,
} from '../../api/delete'
import { toast } from 'react-toastify'
import { Dispatch } from 'react'
import { combineLeadRequest } from '../../api/lead-children.api'

export const saveCustomList = (name: string) => (dispatch: any) => {
  postCustomList(name).then((data) => {
    if (data.ok) {
      toast.success('Custom list was created!')
      dispatch({
        type: SAVE_CUSTOM_LIST,
        payload: {
          name: data.name,
          id: data.id,
        },
      })
    } else {
      data.errors.forEach((item: IServerError) => toast.error(item.message))
    }
  })
}

interface IGetLeadsFilter {
  custom_list?: string | null
  country?: number | null
  status?: number | ''
  search?: string | null
  ordering?: string | null
  created_by?: string | null
}

export const getLeadsListAction = (filter: IGetLeadsFilter) => (
  dispatch: any
) => {
  dispatch(setIsLoading(true))
  getLeadsList(objToQueryConverter(filter)).then((data) => {
    if (data.ok) {
      dispatch(updateLeadsList(data.results))
      dispatch(
        updatePagination({
          count: data.count,
          next: data.next,
          prev: data.previous,
        })
      )
    }
  })
}

const setIsLoading = (payload: boolean) => ({
  type: SET_IS_LOADING,
  payload,
})

export const getCustomListsAction = () => (dispatch: any) => {
  getCustomLists().then((data) => {
    if (data.ok) {
      dispatch(updateCustomListsAction(data.results))
    }
  })
}

export const moveLeadsToCustomAction = (
  selectedList: number,
  selectedLeads: Array<number>
) => (dispatch: any) => {
  moveLeadToCustom(selectedList, selectedLeads).then((data) => {
    if (data.ok) {
      dispatch(unselectAllLeads())
      toast.success('Leads successfully copied')
    } else {
      data.errors.forEach((item: IServerError) => toast.error(item.message))
    }
  })
}

export const deleteCustomListAction = (id: number) => (dispatch: any) => {
  deleteCustomList(id).then((data) => {
    if (data.ok) {
      dispatch(removeCustomList(id))
      toast.success('Custom list was deleted')
    } else {
      data.errors.forEach((item: IServerError) => toast.error(item.message))
    }
  })
}

export const deleteLeadRequest = (id: number) => (dispatch: any) => {
  deleteLeads(id).then((data) => {
    if (data.ok) {
      dispatch(deleteLeadAction(id))
      toast.success('Lead was deleted')
      dispatch({
        type: DECREMENT_LEADS_COUNT,
      })
    } else {
      data.errors.forEach((item: IServerError) => toast.error(item.message))
    }
  })
}

export const deleteCustomLeadRequest = (id: number, custom_list: number) => (
  dispatch: any
) => {
  deleteCustomListLeads(custom_list, id).then((data) => {
    if (data.ok) {
      dispatch(deleteLeadAction(id))
      toast.success('Lead was deleted')
      dispatch({ type: DECREMENT_LEADS_COUNT })
    } else {
      data.errors.forEach((item: IServerError) => toast.error(item.message))
    }
  })
}

export const updatePagination = (object: object) => ({
  type: UPDATE_PAGINATION,
  payload: object,
})

export const loadMoreLeads = (url: string) => (dispatch: any) => {
  dispatch(updatePagination({ is_loading: true }))
  getMoreLeadsList(url).then((data) => {
    if (data.ok) {
      dispatch({
        type: LOAD_MORE_LEADS,
        payload: data.results,
      })
      dispatch(
        updatePagination({
          is_loading: false,
          next: data.next,
          prev: data.previous,
        })
      )
    }
  })
}

export const setStatusesThunk = (
  status: ILeadStatus | null,
  ids: Array<number>
) => (dispatch: Dispatch<any>) => {
  postSetStatuses(status?.id || null, ids)
    .then((data) => {
      if (data.ok) {
        dispatch(setStatusesAction(status || null, ids))
        dispatch(unselectAllLeads())
        toast.success('Statuses were updated!')
      } else {
        data.errors.forEach((item: IServerError) => toast.error(item.message))
      }
    })
    .catch(() => {
      toast.error('Something went wrong!')
    })
}

export const setStatusesAction = (
  status: ILeadStatus | null,
  ids: Array<number>
) => ({
  type: SET_STATUSES,
  payload: {
    status,
    ids,
  },
})

export const updateFilter = (payload: IFilterValues) => ({
  type: UPDATE_FILTER,
  payload,
})

export const updateFilterSorting = (payload: string) => ({
  type: UPDATE_FILTER_SORTING,
  payload,
})

export const removeCustomList = (id: number) => ({
  type: DELETE_CUSTOM_LIST,
  payload: id,
})

export const selectAllLeadsAction = () => ({
  type: SELECT_ALL_LEADS,
})

export const unselectAllLeads = () => ({
  type: UNSELECT_ALL_LEADS,
})

export const setIsLeadSelected = (id: number) => ({
  type: SET_IS_LEAD_SELECTED,
  payload: id,
})

export const updateLeadsList = (payload: any) => ({
  type: UPDATE_LEADS_LIST,
  payload,
})

export const updateCustomListsAction = (payload: Array<ICustomList>) => ({
  type: UPDATE_CUSTOM_LIST,
  payload,
})

export const createLeadAction = (id: number, lead: ILeadFormValues) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: CREATE_NEW_LEAD,
    payload: {
      id,
      lead,
    },
  })
  dispatch({ type: INCREMENT_LEADS_COUNT })
}

export const updateLeadAction = (id: number, lead: ILeadFormValues) => ({
  type: UPDATE_LEAD_DATA,
  payload: {
    id,
    lead,
  },
})

export const deleteLeadAction = (id: number) => {
  return {
    type: DELETE_LEAD,
    payload: id,
  }
}

export const deleteLeadsAction = (ids: Array<number>) => ({
  type: DELETE_LEADS,
  payload: ids,
})

export const deleteFilterItem = (name: string) => {
  return {
    type: DELETE_FILTER_ITEM,
    payload: name,
  }
}

export const toggleCombineMode = () => {
  return {
    type: TOGGLE_COMBINE_MODE,
  }
}

export const applyCombineAction = (parent: number, children: Array<number>) => (
  dispatch: any
) => {
  combineLeadRequest(parent, children).then((data) => {
    if (data.ok) {
      toast.success('Leads were combined!')
      dispatch(deleteLeadsAction(children))
      dispatch(toggleCombineMode())
    }
  })
}

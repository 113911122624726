import { UPDATE_ME, USER_LOGIN, USER_LOGOUT } from '../types'

const initialState = {
  access_token: null,
  is_auth: false,
  info: {
    id: null,
    email: null,
    first_name: null,
    last_name: null,
  },
}

export default function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        ...action.payload,
      }

    case USER_LOGOUT:
      return {
        ...initialState,
      }

    case UPDATE_ME:
      return {
        ...state,
        info: action.payload,
      }
    default:
      return state
  }
}

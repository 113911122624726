import { API_BASE, API_VERSION } from './constants'
import { ILeadFormValues } from '../globalTypes'
import { vesselSubmitPrettier } from './api.helper'

export const putAuthRequest = async (url: string, data: object) => {
  const token = JSON.parse(localStorage.getItem('access_token') || 'null')

  const response = await fetch(`${API_BASE}${API_VERSION}crm/${url}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })

  const json = await response.json()
  json.ok = response.ok

  return await json
}

export const updateLead = (id: number, lead: ILeadFormValues) => {
  return putAuthRequest(`leads/${id}/`, vesselSubmitPrettier(lead))
}

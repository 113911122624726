import React, { useEffect, useState } from 'react'
import styles from './LeadModal.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { LeadModalFormSchema as validationSchema } from '../../validation-schemas'

import { RootState } from '../../redux/reducers/root.reducer'
import { Button, InputLabel, TextField, Select } from '@material-ui/core'
import { MenuItem, FormControl, IconButton, Modal } from '@material-ui/core'
import {
  createNewLeadRequest,
  setIsModalOpen,
  updateLeadRequest,
} from '../../redux/actions/leadForm.action'
import {
  CountryCodeType,
  ICountry,
  ILeadFormValues,
  ILeadStatus,
} from '../../globalTypes'
import { CloseSvg } from '../Icons'
import { getFilters } from '../../api/get'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FieldSearch from '../FieldSearch'
import Phone from '../Phone/Phone'
import StatusColor from '../StatusColor'
import { emptyPhone } from '../../constants/LeadModal.constants'
import { parseString } from '../../helpers'
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg'

const LeadModal = () => {
  const dispatch = useDispatch()

  const modal = useSelector((state: RootState) => state.leadModal)

  const [statuses, setStatuses] = useState([])
  const [countries, setCountries] = useState<Array<ICountry>>([])
  const [countyCodes, setCountryCodes] = useState<Array<CountryCodeType>>([])

  const initialValues: ILeadFormValues = {
    company: modal.form?.company || '',
    country: modal.form?.country || null,
    city: modal.form?.city || '',
    address: modal.form?.address || '',
    emails: modal.form?.emails.length ? modal.form.emails : [''],
    phones: modal.form?.phones.length ? modal.form.phones : [emptyPhone],
    comment: modal.form?.comment || '',
    crewing: modal.form?.crewing || '',
    project: modal.form?.project || '',
    website: modal.form?.website || '',
    status: modal.form?.status?.id || '',
    pic: modal.form?.pic || '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })
  useEffect(() => {
    getFilters(['status', 'country', 'country_codes']).then(
      ({ status, country, country_codes }) => {
        setStatuses(status)
        setCountries(country)
        setCountryCodes(country_codes)
      }
    )
  }, [])

  const handleClose = () => {
    dispatch(setIsModalOpen(false))
  }

  function handleSubmit(values: ILeadFormValues): void {
    if (modal.lead_id) {
      dispatch(updateLeadRequest(modal.lead_id, values))
    } else {
      dispatch(createNewLeadRequest(values))
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | any>
  ): void => {
    formik.handleChange(event)
  }

  const handleAddNewField = (type: string) => {
    const { values } = formik

    formik.setValues({
      ...values,
      [type]: [...(values as any)[type], type === 'phones' ? emptyPhone : ''],
    })
  }

  const handleFieldSearchChange = (name: string, value: string): void => {
    formik.handleChange({ target: { name, value } })
  }

  function handleCodeChange(value: CountryCodeType | null, name: string) {
    const currentIndex = Number(parseString(name, '[', ']'))
    formik.setValues({
      ...values,
      phones: values.phones.map((phone, index) => {
        return index === currentIndex
          ? {
              ...phone,
              country_code: value,
            }
          : phone
      }),
    })
  }

  function handlePhoneChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target
    const currentIndex = Number(parseString(name, '[', ']'))
    formik.setValues({
      ...values,
      phones: values.phones.map((phone, index) => {
        if (index !== currentIndex) return phone
        return {
          ...phone,
          phone: value,
        }
      }),
    })
  }

  function handleFieldDelete(name: string) {
    const { values }: any = formik
    const deletedIndex = Number(parseString(name, '[', ']'))
    const [propName] = name.split('[')

    formik.setValues({
      ...values,
      [propName]: values[propName].filter(
        (item: string, index: number) => index !== deletedIndex
      ),
    })
  }

  const { values, errors, touched } = formik

  return (
    <Modal
      onClose={handleClose}
      open={modal.is_open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <>
        <div className={styles.wrapper}>
          <div className={styles.modal}>
            <button onClick={handleClose} className={styles.close_button}>
              <CloseSvg />
            </button>
            <h2 className={styles.title}>
              {modal.lead_id ? 'Update company' : 'New company'}
            </h2>

            <form onSubmit={formik.handleSubmit} id="lead-form">
              <div className={styles.field}>
                <div className={styles.field}>
                  <FieldSearch
                    onChange={handleFieldSearchChange}
                    name="company"
                    label="Company"
                    value={values.company}
                  />
                </div>
              </div>

              <div className={styles.field}>
                <Autocomplete
                  id="country-cms"
                  options={countries}
                  clearOnBlur
                  getOptionLabel={(option: ICountry) => option.name}
                  value={values.country}
                  onChange={(event: any, value: ICountry | null) => {
                    formik.setValues({ ...values, country: value })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      size="small"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                />
              </div>

              <div className={styles.field}>
                <FieldSearch
                  onChange={handleFieldSearchChange}
                  name="city"
                  label="City"
                  value={values.city}
                />
              </div>

              <div className={styles.field}>
                <FieldSearch
                  onChange={handleFieldSearchChange}
                  name="address"
                  label="Address"
                  value={values.address}
                />
              </div>

              <div className={styles.field}>
                <FieldSearch
                  onChange={handleFieldSearchChange}
                  name="pic"
                  label="PIC"
                  value={values.pic}
                />
              </div>

              <h4 className={styles.main_title}>Phones</h4>
              {values.phones.map((item, index) => {
                const isValid =
                  !!errors.phones &&
                  !!touched.phones &&
                  touched.phones &&
                  !!errors.phones[index]
                const isCanDelete = values.phones.length > 1
                return (
                  <div key={index} className={styles.field}>
                    <Phone
                      countyCodes={countyCodes}
                      onCodeChange={handleCodeChange}
                      onPhoneChange={handlePhoneChange}
                      onPhoneDelete={handleFieldDelete}
                      name={`phones[${index}]`}
                      phone={values.phones[index].phone}
                      code={values.phones[index].country_code}
                      isCanDelete={isCanDelete}
                      error={isValid}
                    />
                  </div>
                )
              })}

              <div className={styles.field}>
                <Button
                  onClick={() => handleAddNewField('phones')}
                  color="primary"
                >
                  Add new phone
                </Button>
              </div>

              <h4 className={styles.main_title}>Emails</h4>
              {values.emails.map((item, index) => {
                const isValid =
                  !!errors.emails &&
                  !!touched.emails &&
                  touched.emails &&
                  !!errors.emails[index]
                const name = `emails[${index}]`
                const isCanDelete = values.emails.length > 1
                return (
                  <div key={index} className={styles.row}>
                    <div className={styles.field}>
                      <FieldSearch
                        onChange={handleFieldSearchChange}
                        name={name}
                        altName="email"
                        label={`Email ${index === 0 ? '*' : ''}`}
                        value={values.emails[index]}
                        error={isValid}
                        helperText={errors.emails ? errors.emails[index] : null}
                      />
                    </div>
                    {isCanDelete && (
                      <div className={styles.delete_button}>
                        <IconButton onClick={() => handleFieldDelete(name)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    )}
                  </div>
                )
              })}
              <div className={styles.field}>
                <Button
                  onClick={() => handleAddNewField('emails')}
                  color="primary"
                >
                  Add new email
                </Button>
              </div>

              <div className={styles.field}>
                <FieldSearch
                  onChange={handleFieldSearchChange}
                  name="project"
                  label="Project"
                  value={values.project}
                />
              </div>

              <div className={styles.field}>
                <FieldSearch
                  onChange={handleFieldSearchChange}
                  name="crewing"
                  label="Crewing"
                  value={values.crewing}
                />
              </div>

              <div className={styles.field}>
                <FieldSearch
                  onChange={handleFieldSearchChange}
                  name="website"
                  label="Website"
                  value={values.website}
                />
              </div>

              <div className={styles.field}>
                <TextField
                  name="comment"
                  label="Comment"
                  size="small"
                  multiline
                  rows={3}
                  rowsMax={10}
                  variant="outlined"
                  onChange={handleInputChange}
                  value={values.comment}
                  error={!!(errors.comment && touched.comment)}
                  helperText={
                    errors.comment && touched.comment ? errors.comment : null
                  }
                />
              </div>

              <div className={styles.field}>
                <FormControl variant="outlined" size="small">
                  <InputLabel>Lead status</InputLabel>
                  <Select
                    id="status-label"
                    name="status"
                    value={values.status}
                    onChange={handleInputChange}
                  >
                    <MenuItem value={''}>None</MenuItem>
                    {statuses.map((item: ILeadStatus) => (
                      <MenuItem key={item.id} value={item.id}>
                        <span>{item.name}</span>
                        <StatusColor color={item.color} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </form>
          </div>
        </div>
        <div className={`${styles.field} ${styles.button_wrapper}`}>
          <Button
            form="lead-form"
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </div>
      </>
    </Modal>
  )
}

export default LeadModal

import { ILeadFormValues } from '../globalTypes'

export const objToQueryConverter = (obj: any): string => {
  return Object.keys(obj)
    .map((key) => {
      const title = key
      const value = Array.isArray(obj[key]) ? obj[key].join(',') : obj[key]

      return value ? title + '=' + value : 'empty'
    })
    .filter((el) => el !== 'empty')
    .join('&')
}

export const emptyStringToNull = (object: ILeadFormValues) => {
  const result: ILeadFormValues = { ...object }
  Object.keys(object).forEach((key) => {
    // @ts-ignore
    if (Array.isArray(object[key])) {
      // @ts-ignore
      result[key] = object[key].filter((item: Array<string>) => item !== '')
    } else {
      // @ts-ignore
      result[key] = object[key] === '' ? null : object[key]
    }
  })
  return result
}

export const downloadFile = (fileName: string, data: any) => {
  const file = window.URL.createObjectURL(data)
  const anchor = document.createElement('a')
  anchor.download = `${fileName}`
  anchor.href = file
  anchor.click()
}

export const vesselSubmitPrettier = (lead: ILeadFormValues) => ({
  ...lead,
  phones: lead.phones.find((item) => item.country_code)
    ? lead.phones.map((item) => ({
        ...item,
        country_code: item.country_code?.id,
      }))
    : [],
  country: lead.country?.id,
})

import React, { useState } from 'react'
import styles from './ExportModal.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setIsUiModalOpen } from '../../redux/actions/ui.action'
import Modal from '@material-ui/core/Modal'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { getCustomListExel } from '../../api/get'
import { ICustomList, IParams } from '../../globalTypes'
import { RootState } from '../../redux/reducers/root.reducer'
import { useParams } from 'react-router-dom'
import { downloadFile } from '../../api/api.helper'

interface IExportModalProps {
  isOpen?: boolean
}

type ValuesType = {
  [key: string]: any
}

const ExportModal: React.FC<IExportModalProps> = ({ isOpen = true }) => {
  const dispatch = useDispatch()

  const { custom_list }: IParams = useParams()
  const custom_lists: Array<ICustomList> = useSelector(
    (state: RootState) => state.leads.custom_lists
  )

  const custom_list_name = custom_list
    ? custom_lists.find((item: ICustomList) => item.id === +custom_list)?.name
    : 'all'

  const [values, setValues] = useState<ValuesType>({
    email: true,
    phone: false,
    crewing: false,
    company: false,
    country: false,
    city: false,
    address: false,
    project: false,
    website: false,
    pic: false,
    comment: false,
    created_by: false,
  })

  function handleCheckboxClick(event: React.ChangeEvent<HTMLInputElement>) {
    setValues({ ...values, [event.target.name]: event.target.checked })
  }

  function capitalizeString(str: string) {
    const firstLetter = str.substr(0, 1)
    return firstLetter.toUpperCase() + str.substr(1)
  }

  const handleClose = () => {
    dispatch(setIsUiModalOpen('excel_export', false))
  }

  function handleSubmit() {
    if (custom_list) {
      getCustomListExel(
        +custom_list,
        Object.keys(values).filter((item) => values[item])
      ).then((data) => {
        downloadFile(`${custom_list_name}_leads.xls`, data)
        handleClose()
      })
    }
  }

  return (
    <Modal
      onClose={handleClose}
      open={isOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={styles.modal}>
        <h2 className={styles.title}>
          Select fields for export "{custom_list_name}"
        </h2>
        <div className={styles.items}>
          {Object.keys(values).map((item: string) => (
            <div className={styles.item} key={item}>
              <FormControlLabel
                label={capitalizeString(item.replaceAll('_', ' '))}
                control={
                  <Checkbox
                    disabled={item === 'email'}
                    onChange={handleCheckboxClick}
                    size="small"
                    name={item}
                    checked={values[item]}
                    color="secondary"
                  />
                }
              />
            </div>
          ))}
        </div>

        <div className={styles.footer}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            export
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ExportModal

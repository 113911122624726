import React, { useEffect } from 'react'
import LeadsFilter from '../components/LeadsFilter/LeadsFilter'
import LeadTable from '../components/LeadTable/LeadTable'
import { useDispatch, useSelector } from 'react-redux'
import LeadModal from '../components/LeadModal/LeadModal'
import { useParams } from 'react-router-dom'
import {
  getCustomListsAction,
  getLeadsListAction,
} from '../redux/actions/leads.action'
import { RootState } from '../redux/reducers/root.reducer'
import Header from '../components/Header/Header'
import { updateUserInformation } from '../redux/actions/user.action'
import FilterIndicator from '../components/FilterIndicator/FilterIndicator'
import { Fab } from '@material-ui/core'
import { setIsUiModalOpen } from '../redux/actions/ui.action'
import InfoModal from '../components/InfoModal/InfoModal'
import StatusEditorModal from '../components/StatusEditorModal/StatusEditorModal'
import ExportModal from '../components/ExportModal/ExportModal'
import { IParams } from '../globalTypes'

function LeadsPage() {
  const dispatch = useDispatch()

  const is_modal_open = useSelector(
    (state: RootState) => state.leadModal.is_open
  )

  const { info, status_editor, excel_export } = useSelector(
    (state: RootState) => state.ui.modals
  )

  const ordering = useSelector((state: RootState) => state.leads.ordering)
  const filter = useSelector((state: RootState) => state.leads.filter)

  const { custom_list }: IParams = useParams()

  const handleInfoClick = () => {
    dispatch(setIsUiModalOpen('info', true))
  }

  useEffect(() => {
    dispatch(
      getLeadsListAction({
        custom_list: custom_list || null,
        ordering,
        ...filter,
        country: filter.country ? filter.country.id : '',
        status: filter.status ? filter.status.id : '',
        created_by: filter.user ? filter.user.id : '',
        user: '',
      })
    )
  }, [dispatch, custom_list, ordering, filter])

  useEffect(() => {
    dispatch(getCustomListsAction())
    dispatch(updateUserInformation())
  }, [dispatch])

  return (
    <div className="app">
      <Header />
      <LeadsFilter />
      <FilterIndicator />
      <LeadTable />
      {is_modal_open && <LeadModal />}
      {info.is_open && <InfoModal />}
      {status_editor.is_open && <StatusEditorModal />}
      {excel_export.is_open && <ExportModal />}
      <div onClick={handleInfoClick} className="floating-button">
        <Fab size="small" color="primary" aria-label="add">
          <span className="floating-button-text">?</span>
        </Fab>
      </div>
    </div>
  )
}

export default LeadsPage

import { SET_IS_UI_MODAL_OPEN } from '../types'

const initialState = {
  modals: {
    info: {
      is_open: false,
    },
    status_editor: {
      is_open: false,
    },
    excel_export: {
      is_open: false,
    },
  },
}

export default function uiReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_IS_UI_MODAL_OPEN:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload.name]: {
            is_open: action.payload.status,
          },
        },
      }

    default:
      return state
  }
}

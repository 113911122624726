import React, { useState } from 'react'
import styles from './LeadTable.module.scss'
import cn from 'classnames'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as EditIcon } from '../../assets/edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg'
import { ILead, IParams } from '../../globalTypes'
import {
  applyCombineAction,
  deleteCustomLeadRequest,
  deleteLeadRequest,
  setIsLeadSelected,
} from '../../redux/actions/leads.action'
import { editLeadAction } from '../../redux/actions/leadForm.action'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from '../../redux/reducers/root.reducer'
import { Tooltip } from '@material-ui/core'
import { ArrowSvg } from '../Icons'
import { utcToLocalDate } from '../../helpers'

interface ILeadTableItemProps {
  item: ILead
  isCombineActive: boolean
}

const LeadTableItem: React.FC<ILeadTableItemProps> = ({
  item,
  isCombineActive,
}) => {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)

  const { custom_list }: IParams = useParams()

  const selectedLeadIds: Array<number> = useSelector(
    (state: RootState) => state.leads.leads_list
  )
    .filter((item: ILead) => item.is_selected)
    .map((item: ILead) => item.id)

  const handleLeadDelete = (id: number, name: string) => {
    if (window.confirm(`Do you want to delete ${name || 'this company'}?`)) {
      if (custom_list) {
        dispatch(deleteCustomLeadRequest(id, +custom_list))
      } else {
        dispatch(deleteLeadRequest(id))
      }
    }
  }

  const applyCombine = () => {
    dispatch(
      applyCombineAction(
        item.id,
        selectedLeadIds.filter((el) => el !== item.id)
      )
    )
  }

  const handleLeadEdit = (id: number) => {
    dispatch(editLeadAction(id, item))
  }

  const handleCheckboxClick = (event: React.SyntheticEvent, id: number) => {
    event.stopPropagation()
    dispatch(setIsLeadSelected(id))
  }

  const phones = item.phones
    ? item.phones
        .map(({ country_code, phone }) => `${country_code?.code}${phone}`)
        .join(', ')
    : '—'

  return (
    <div
      key={item.id}
      className={cn(styles.row_wrapper, {
        [styles.active]: item.is_selected,
        [styles.combine_mode]: isCombineActive,
      })}
    >
      <div className={cn(styles.row, 'container')}>
        <div className={styles.field}>{item.company || '—'}</div>
        <div className={styles.field}>{item.country?.name || '—'}</div>
        <div className={styles.field}>{item.pic || '—'}</div>
        <div className={styles.field}>{phones}</div>
        <div className={styles.field}>
          {item.emails.length ? item.emails.join(', ') : '—'}
        </div>

        <div className={styles.last_field}>
          <Checkbox
            disabled={isCombineActive && selectedLeadIds.length < 3}
            size="small"
            checked={item.is_selected}
            onChange={(event) => handleCheckboxClick(event, item.id)}
            color="secondary"
          />
        </div>
        <div className={styles.controls}>
          {isCombineActive ? (
            <button onClick={applyCombine} className={styles.apply_combine}>
              Main
            </button>
          ) : (
            <>
              <Tooltip title="Edit">
                <IconButton
                  onClick={() => handleLeadEdit(item.id)}
                  aria-label="delete"
                  size="small"
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete">
                <IconButton
                  onClick={() => handleLeadDelete(item.id, item.company)}
                  aria-label="delete"
                  size="small"
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>

        <div onClick={() => setIsOpen(!isOpen)} className={styles.arrow}>
          <ArrowSvg rotate={isOpen} />
        </div>

        {item.status && (
          <Tooltip placement="right" title={item.status.name}>
            <div
              className={styles.lead_status}
              style={{
                background: item.status ? item.status.color : 'transparent',
              }}
            />
          </Tooltip>
        )}
      </div>

      {isOpen && (
        <div
          className={cn(styles.second_row, 'container')}
          style={{
            borderColor: item.status ? item.status.color : 'transparent',
          }}
        >
          {item.city && (
            <div className={styles.second_row_item}>
              <b>City:</b> <span>{item.city}</span>
            </div>
          )}
          {item.crewing && (
            <div className={styles.second_row_item}>
              <b>Crewing: </b> <span>{item.crewing}</span>
            </div>
          )}
          {item.website && (
            <div className={styles.second_row_item}>
              <b>Website: </b>
              <span>
                <a href={item.website.includes("http")?item.website:`http://${item.website}`}>{item.website}</a>
              </span>
            </div>
          )}
          {item.created_by && (
            <div className={styles.second_row_item}>
              <b>Created by: </b>
              <span>
                {item.created_by.first_name} {item.created_by.last_name}
                {item.created_at && ` (${utcToLocalDate(item.created_at)})`}
              </span>
            </div>
          )}
          {item.updated_by && (
            <div className={styles.second_row_item}>
              <b>Updated by: </b>
              <span>
                {item.updated_by.first_name} {item.updated_by.last_name}
                {item.updated_at && ` (${utcToLocalDate(item.updated_at)})`}
              </span>
            </div>
          )}
          {item.comment && (
            <div className={styles.second_row_item}>
              <b>Comment: </b> <span>{item.comment}</span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default LeadTableItem

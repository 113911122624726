import { API_BASE, API_VERSION } from './constants'

export const deleteRequest = async (url: string) => {
  const token = JSON.parse(localStorage.getItem('access_token') || 'null')

  const response = await fetch(`${API_BASE}${API_VERSION}crm/${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'DELETE',
  })

  const json = await response.json()
  json.ok = response.ok

  return json
}

export const deleteCustomList = async (id: number) => {
  return await deleteRequest(`custom-lists/${id}/`)
}

export const deleteLeads = async (id: number) => {
  return await deleteRequest(`leads/${id}/`)
}

export const deleteCustomListLeads = async (id: number, leads: number) => {
  return await deleteRequest(`custom-lists/${id}/?leads=${leads}`)
}

import { SET_IS_LEAD_MODAL_OPEN, START_EDIT_LEAD } from '../types'
import { ILead, ILeadFormValues, IServerError } from '../../globalTypes'
import { updateLead } from '../../api/put'
import { emptyStringToNull } from '../../api/api.helper'
import { toast } from 'react-toastify'
import { createLeadAction, updateLeadAction } from './leads.action'
import { postNewLead } from '../../api/post'

export const editLeadAction = (id: number, lead: ILead) => ({
  type: START_EDIT_LEAD,
  payload: {
    id,
    lead,
  },
})

export const setIsModalOpen = (payload: boolean) => {
  return {
    type: SET_IS_LEAD_MODAL_OPEN,
    payload: payload,
  }
}

export const createNewLeadRequest = (lead: ILeadFormValues) => (
  dispatch: any
) => {
  postNewLead(emptyStringToNull(lead)).then((data) => {
    if (data.ok) {
      dispatch(
        createLeadAction(data.id, {
          ...lead,
          created_by: data.created_by,
        })
      )
      dispatch(setIsModalOpen(false))
      toast.success(`${lead.company || 'Company'} was created`)
    } else {
      data.errors.forEach((item: IServerError) => toast.error(item.message))
    }
  })
}

export const updateLeadRequest = (id: number, lead: ILeadFormValues) => (
  dispatch: any
) => {
  updateLead(id, emptyStringToNull(lead)).then((data) => {
    if (data.ok) {
      dispatch(updateLeadAction(id, data))
      dispatch(setIsModalOpen(false))
      toast.success(`${lead.company || 'Company'} was updated`)
    } else {
      data.errors.forEach((item: IServerError) => toast.error(item.message))
    }
  })
}

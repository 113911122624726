import React from 'react'
import styles from './LeadsFilter.module.scss'
import { PlusSvg, XlsSvg } from '../Icons'
import CustomListDropDown from '../CustomListDropDown/CustomListDropDown'
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from 'react-redux'
import { setIsModalOpen } from '../../redux/actions/leadForm.action'
import { useParams } from 'react-router-dom'

import Filter from '../Filter/Filter'
import { setIsUiModalOpen } from '../../redux/actions/ui.action'
import { ILead, IParams } from '../../globalTypes'
import { toggleCombineMode } from '../../redux/actions/leads.action'
import { RootState } from '../../redux/reducers/root.reducer'
import StatusMultiEditor from '../StatusMultiEditor/StatusMultiEditor'

function LeadsFilter() {
  const dispatch = useDispatch()

  const { custom_list }: IParams = useParams()

  const combine_mode: { is_active: boolean } = useSelector(
    (state: RootState) => state.leads.combine_mode
  )

  const selectedLeads: Array<number> = useSelector(
    (state: RootState) => state.leads.leads_list
  )
    .filter((item: ILead) => item.is_selected)
    .map((item: ILead) => item.id)

  const handleAddClick = () => {
    dispatch(setIsModalOpen(true))
  }

  const handleExportClick = () => {
    dispatch(setIsUiModalOpen('excel_export', true))
  }

  const handleCombineClick = () => {
    dispatch(toggleCombineMode())
  }

  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className={styles.filter}>
          <div className={styles.left_side}>
            <Filter />

            <div className={styles.move_to}>
              Copy to
              <CustomListDropDown />
            </div>
          </div>

          <StatusMultiEditor />

          <div className={styles.right_side}>
            <Button
              onClick={handleCombineClick}
              disabled={selectedLeads.length < 2}
              type="submit"
              variant="outlined"
              color="primary"
            >
              {combine_mode.is_active ? 'Cancel' : 'Combine'}
            </Button>

            <Button
              style={{ color: '#81BB41' }}
              onClick={handleAddClick}
              startIcon={<PlusSvg size={18} fill="#81BB41" />}
            >
              Add
            </Button>

            <Button
              disabled={!custom_list}
              onClick={handleExportClick}
              style={{ padding: '6px 10px' }}
              variant={custom_list ? 'text' : 'contained'}
              size="small"
              startIcon={
                <XlsSvg
                  size={18}
                  fill={custom_list ? 'var(--regular-color)' : '#999'}
                />
              }
            >
              Export to Excel
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeadsFilter

import { API_BASE, API_VERSION } from './constants'

// main request
export const getRequest = async (url: string) => {
  const token = JSON.parse(localStorage.getItem('access_token') || 'null')
  const full_url = url === 'me/' ? url : `${API_VERSION}crm/${url}`

  const response = await fetch(`${API_BASE}${full_url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  })

  if (response.status === 401) {
    localStorage.removeItem('access_token')
    window.location.reload()
  }

  const json = await response.json()
  json.ok = response.ok

  return json
}

export const getFileRequest = async (url: string) => {
  const token = JSON.parse(localStorage.getItem('access_token') || 'null')

  return await fetch(`${API_BASE}${API_VERSION}crm/${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  })
}

// api routes
export const getCustomLists = async () => {
  return await getRequest(`custom-lists/`)
}

export const getLeadsList = async (query: string) => {
  return await getRequest(`leads/?page_size=12&${query}`)
}

export const getMoreLeadsList = async (url: string) => {
  const query = url.split('?')[1]
  return await getRequest(`leads/?${query}`)
}

export const getCustomListExel = async (id: number, fields?: Array<string>) => {
  const query = fields
    ? `?fields=${fields.join('&fields=')}`.replace('phone', 'phones')
    : ''
  const response = await getFileRequest(`export-custom-list/${id}/${query}`)
  return response.blob()
}

export const getFilters = async (
  models: Array<'user' | 'country' | 'status' | 'country_codes'>
) => {
  return await getRequest(`filters/?models=${models}`)
}

export const getAutocompleteSearch = async (text: string, field: string) => {
  const encoded_text = text.replaceAll('+', '%2B')

  const result = await getRequest(
    `search/?text=${encoded_text}&field=${field}&limit=15`
  )
  delete result.ok
  return result
}

export const getUserInfoRequest = async () => {
  return await getRequest(`me/`)
}

import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { getAutocompleteSearch } from '../api/get'

interface IFieldSearchProps {
  onChange: (event: any, value: string) => void
  name: string
  label: string
  value: string
  altName?: string | null
  // All other props
  [rest: string]: any
}

const FieldSearch: React.FC<IFieldSearchProps> = ({
  onChange,
  name,
  label,
  value,
  altName = null,
  ...rest
}) => {
  const [options, setOptions] = useState<Array<string>>([])
  React.useEffect(() => {
    if (value !== '' && value.length > 2) {
      getAutocompleteSearch(value, altName || name).then((data) => {
        setOptions(data)
      })
    }
  }, [altName, value, name])

  return (
    <Autocomplete
      options={[value, ...options]}
      value={value}
      closeIcon={false}
      onChange={(event: any, value: string | null) => {
        event.stopPropagation()
        onChange(name, value || '')
      }}
      filterOptions={(options) =>
        options.filter((option, index) => option !== '' && index)
      }
      onInputChange={(event, newInputValue) => {
        onChange(name, newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          name={name}
          variant="outlined"
          {...rest}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  )
}

export default FieldSearch

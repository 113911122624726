import { UPDATE_ME, USER_LOGIN, USER_LOGOUT } from '../types'
import { ILoginForm } from '../../globalTypes'
import { userLoginRequest } from '../../api/post'
import { ThunkDispatch } from 'redux-thunk'
import { getUserInfoRequest } from '../../api/get'
import { toast } from 'react-toastify'

export const userLogin = (payload: ILoginForm) => (
  dispatch: ThunkDispatch<null, void, any>
) => {
  userLoginRequest(payload).then((data) => {
    if (data.ok) {
      localStorage.setItem('access_token', JSON.stringify(data.access_token))
      dispatch(
        addToken({
          access_token: data.access_token,
          is_auth: true,
        })
      )
    } else {
      toast.error(data.error)
    }
  })
}

export const updateUserInformation = () => (
  dispatch: ThunkDispatch<null, void, any>
) => {
  getUserInfoRequest().then((data) => {
    if (data.ok) {
      dispatch({
        type: UPDATE_ME,
        payload: data,
      })
    } else {
      dispatch(userLogout())
    }
  })
}

export const userLogout = () => {
  localStorage.removeItem('access_token')
  return { type: USER_LOGOUT }
}

interface AddTokenPayloadType {
  access_token: string
  is_auth: boolean
}

interface AddTokenActionType {
  type: typeof USER_LOGIN
  payload: AddTokenPayloadType
}

export const addToken = (payload: AddTokenPayloadType): AddTokenActionType => {
  return {
    type: USER_LOGIN,
    payload: payload,
  }
}

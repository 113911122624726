export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'
export const UPDATE_ME = 'UPDATE_ME'

// leadForm
export const SET_IS_LEAD_MODAL_OPEN = 'SET_IS_LEAD_MODAL_OPEN'
export const START_EDIT_LEAD = 'START_EDIT_LEAD'

// custom lists
export const UPDATE_CUSTOM_LIST = 'UPDATE_CUSTOM_LIST'
export const SAVE_CUSTOM_LIST = 'SAVE_CUSTOM_LIST'
export const UPDATE_LEAD_DATA = 'UPDATE_LEAD_DATA'
export const CREATE_NEW_LEAD = 'CREATE_NEW_LEAD'

// leads
export const UPDATE_LEADS_LIST = 'UPDATE_LEADS_LIST'
export const SET_IS_LEAD_SELECTED = 'SET_IS_LEAD_SELECTED'
export const UNSELECT_ALL_LEADS = 'UNSELECT_ALL_LEADS'
export const SELECT_ALL_LEADS = 'SELECT_ALL_LEADS'
export const DELETE_CUSTOM_LIST = 'DELETE_CUSTOM_LIST'
export const DELETE_LEAD = 'DELETE_LEAD'
export const DELETE_LEADS = 'DELETE_LEADS'

// filters
export const UPDATE_FILTER_SORTING = 'UPDATE_FILTER_SORTING'
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const DELETE_FILTER_ITEM = 'DELETE_FILTER_ITEM'

// pagination
export const UPDATE_PAGINATION = 'UPDATE_PAGINATION'
export const LOAD_MORE_LEADS = 'LOAD_MORE_LEADS'
export const DECREMENT_LEADS_COUNT = 'DECREMENT_LEADS_COUNT'
export const INCREMENT_LEADS_COUNT = 'INCREMENT_LEADS_COUNT'

// ui
export const SET_IS_UI_MODAL_OPEN = 'SET_IS_UI_MODAL_OPEN'

// status editor
export const SET_CURRENT_ON_EDIT = 'SET_CURRENT_ON_EDIT'
export const UPDATE_STATUSES_LIST = 'UPDATE_STATUSES_LIST'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const DELETE_STATUS = 'DELETE_STATUS'
export const SET_STATUSES = 'SET_STATUSES'

// combine mode
export const TOGGLE_COMBINE_MODE = 'TOGGLE_COMBINE_MODE'

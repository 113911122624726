import {
  CREATE_NEW_LEAD,
  DECREMENT_LEADS_COUNT,
  DELETE_CUSTOM_LIST,
  DELETE_FILTER_ITEM,
  DELETE_LEAD,
  DELETE_LEADS,
  INCREMENT_LEADS_COUNT,
  LOAD_MORE_LEADS,
  SAVE_CUSTOM_LIST,
  SELECT_ALL_LEADS,
  SET_IS_LEAD_SELECTED,
  SET_IS_LOADING,
  SET_STATUSES,
  TOGGLE_COMBINE_MODE,
  UNSELECT_ALL_LEADS,
  UPDATE_CUSTOM_LIST,
  UPDATE_FILTER,
  UPDATE_FILTER_SORTING,
  UPDATE_LEAD_DATA,
  UPDATE_LEADS_LIST,
  UPDATE_PAGINATION,
} from '../types'
import { ICustomList, ILead } from '../../globalTypes'

const initialState = {
  custom_lists: [],
  ordering: 'company',
  combine_mode: {
    is_active: false,
  },
  filter: {
    search: '',
    company: '',
    country: null,
    status: '',
    user: '',
    city: '',
    phone: '',
    email: '',
  },
  pagination: {
    count: null,
    next: null,
    prev: null,
    is_loading: false,
  },
  leads_list: [],
  loading: false,
}
export default function leadsReducer(state = initialState, action: any) {
  switch (action.type) {
    case SAVE_CUSTOM_LIST:
      return {
        ...state,
        custom_lists: [...state.custom_lists, action.payload],
      }

    case UPDATE_CUSTOM_LIST:
      return {
        ...state,
        custom_lists: action.payload,
      }

    case UPDATE_LEADS_LIST:
      return {
        ...state,
        loading: false,
        leads_list: action.payload.map((lead: ILead) => ({
          ...lead,
          is_selected: false,
        })),
      }

    case SET_IS_LEAD_SELECTED:
      return {
        ...state,
        leads_list: state.leads_list.map((lead: ILead) => ({
          ...lead,
          is_selected:
            lead.id === action.payload ? !lead.is_selected : lead.is_selected,
        })),
      }

    case UNSELECT_ALL_LEADS:
      return {
        ...state,
        leads_list: state.leads_list.map((lead: ILead) => ({
          ...lead,
          is_selected: false,
        })),
      }

    case SELECT_ALL_LEADS:
      return {
        ...state,
        leads_list: state.leads_list.map((lead: ILead) => ({
          ...lead,
          is_selected: true,
        })),
      }

    case DELETE_CUSTOM_LIST:
      return {
        ...state,
        custom_lists: state.custom_lists.filter(
          (item: ICustomList) => item.id !== action.payload
        ),
      }

    case UPDATE_LEAD_DATA:
      return {
        ...state,
        loading: false,
        leads_list: state.leads_list.map((lead: ILead) => {
          if (action.payload.id === lead.id) {
            return {
              ...lead,
              ...action.payload.lead,
            }
          } else {
            return lead
          }
        }),
      }

    case CREATE_NEW_LEAD:
      return {
        ...state,
        leads_list: [
          { ...action.payload.lead, id: action.payload.id, is_selected: false },
          ...state.leads_list,
        ],
      }

    case DELETE_LEAD:
      return {
        ...state,
        leads_list: state.leads_list.filter(
          (item: ILead) => item.id !== action.payload
        ),
      }

    case UPDATE_FILTER_SORTING:
      return {
        ...state,
        ordering: action.payload,
      }

    case UPDATE_FILTER:
      return {
        ...state,
        filter: action.payload,
      }

    case DELETE_FILTER_ITEM:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload]: null,
        },
      }

    case SET_IS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }

    case LOAD_MORE_LEADS:
      return {
        ...state,
        leads_list: [
          ...state.leads_list,
          ...action.payload.map((lead: ILead) => ({
            ...lead,
            is_selected: false,
          })),
        ],
      }

    case UPDATE_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }

    case DECREMENT_LEADS_COUNT:
      const counter_for_dec = state.pagination.count
      return {
        ...state,
        pagination: {
          ...state.pagination,
          count: counter_for_dec ? counter_for_dec - 1 : null,
        },
      }

    case INCREMENT_LEADS_COUNT:
      const counter_for_inc = state.pagination.count
      return {
        ...state,
        pagination: {
          ...state.pagination,
          count: counter_for_inc ? counter_for_inc + 1 : null,
        },
      }

    case DELETE_LEADS:
      return {
        ...state,
        leads_list: state.leads_list.filter(
          (item: ILead) => !action.payload.includes(item.id)
        ),
      }

    //  Combine mode cases
    case TOGGLE_COMBINE_MODE:
      return {
        ...state,
        combine_mode: {
          is_active: !state.combine_mode.is_active,
        },
      }

    // Set Statuses
    case SET_STATUSES:
      return {
        ...state,
        leads_list: state.leads_list.map((item: ILead) => {
          const { status, ids } = action.payload
          if (ids.includes(item.id)) {
            return {
              ...item,
              status,
            }
          } else {
            return item
          }
        }),
      }

    default:
      return state
  }
}

import { ILeadStatus } from '../globalTypes'
import { postAuthRequest } from './post'
import { putAuthRequest } from './put'
import { deleteRequest } from './delete'

export const postNewStatusRequest = (status: ILeadStatus) => {
  return postAuthRequest(`statuses/`, status)
}

export const putStatusRequest = (status: ILeadStatus) => {
  return putAuthRequest(`statuses/${status.id}/`, status)
}

export const deleteStatusRequest = (id: number) => {
  return deleteRequest(`statuses/${id}/`)
}

import { combineReducers } from 'redux'
import leadsReducer from './leads.reducer'
import userReducer from './user.reducer'
import leadModalReducer from './leadForm.reducer'
import uiReducer from './ui.reducer'
import statusCreatorReducer from './statusCreator.reducer'

export const rootReducer = combineReducers({
  leads: leadsReducer,
  user: userReducer,
  leadModal: leadModalReducer,
  ui: uiReducer,
  statusCreator: statusCreatorReducer,
})

export type RootState = ReturnType<typeof rootReducer>

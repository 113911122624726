import React from 'react'
import styles from './Phone.module.scss'
import TextField from '@material-ui/core/TextField'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import { CountryCodeType } from '../../globalTypes'
import { IconButton } from '@material-ui/core'
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg'

const filterOptions = createFilterOptions({
  stringify: (option: CountryCodeType) =>
    `${option.code}, ${option.country.name}`,
})

interface IPhoneProps {
  countyCodes: Array<CountryCodeType>
  onCodeChange: (value: CountryCodeType | null, name: string) => void
  onPhoneChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onPhoneDelete: (name: string) => void
  isCanDelete: boolean
  name: string
  phone: string | null
  code: CountryCodeType | null
  error?: boolean
}

const Phone: React.FC<IPhoneProps> = ({
  countyCodes,
  onCodeChange,
  onPhoneChange,
  onPhoneDelete,
  isCanDelete,
  name,
  phone,
  code,
  error = false,
}) => {
  function handleCodeChange(event: any, value: CountryCodeType | null) {
    onCodeChange(value, name)
  }

  function errorMessageCreator() {
    if (phone && phone.length < 5) {
      return `Phone number can't be less then 5 chars`
    }

    return 'Invalid phone number'
  }

  return (
    <div className={styles.block}>
      <div className={styles.row}>
        <Autocomplete
          closeIcon={null}
          value={code}
          options={countyCodes}
          filterOptions={filterOptions}
          className={styles.code_field}
          getOptionLabel={(option: CountryCodeType) => option.code}
          renderOption={(option) => <PhoneAutocompleteItem option={option} />}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={handleCodeChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Code"
              name={name}
              error={error}
              size="small"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
        <TextField
          onChange={onPhoneChange}
          value={phone || ''}
          name={name}
          label="Phone number"
          size="small"
          error={error}
          type="number"
          fullWidth
          variant="outlined"
          inputProps={{
            autoComplete: 'off',
          }}
        />
        {isCanDelete && (
          <div className={styles.delete_button}>
            <IconButton aria-label="delete" onClick={() => onPhoneDelete(name)}>
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
      {error && (
        <div className={styles.error_message}>{errorMessageCreator()}</div>
      )}
    </div>
  )
}

function PhoneAutocompleteItem({ option }: { option: CountryCodeType }) {
  return (
    <div className={styles.select_field}>
      <span>{option.country.iso2}</span>
      <span className={styles.select_field_code}>{option.code}</span>
    </div>
  )
}

export default Phone

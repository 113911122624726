import {
  DELETE_STATUS,
  SET_CURRENT_ON_EDIT,
  UPDATE_STATUS,
  UPDATE_STATUSES_LIST,
} from '../types'
import { ILeadStatus } from '../../globalTypes'

const initialState = {
  statuses: [],
  current_on_edit: false,
}

export default function statusCreatorReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case SET_CURRENT_ON_EDIT:
      return {
        ...state,
        current_on_edit: action.payload,
      }

    case UPDATE_STATUSES_LIST:
      return {
        ...state,
        statuses: action.payload,
      }

    case UPDATE_STATUS:
      return {
        ...state,
        statuses: state.statuses.map((item: ILeadStatus) =>
          action.payload.id === item.id ? action.payload : item
        ),
      }

    case DELETE_STATUS:
      return {
        ...state,
        statuses: state.statuses.filter(
          (item: ILeadStatus) => item.id !== action.payload
        ),
      }

    default:
      return state
  }
}

import React from 'react'
import styles from './CustomListDropDown.module.scss'
import { PlusSvg, ArrowSvg } from '../Icons'
import SelectItem from '../SelectItem/SelectItem'
import TextField from '@material-ui/core/TextField'
import { Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/root.reducer'
import {
  moveLeadsToCustomAction,
  saveCustomList,
} from '../../redux/actions/leads.action'
import { ICustomList, ILead } from '../../globalTypes'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

interface ICustomListDropDownProps {}

const CustomListDropDown: React.FC<ICustomListDropDownProps> = () => {
  const dispatch = useDispatch()

  const lists: Array<ICustomList> = useSelector(
    (state: RootState) => state.leads.custom_lists
  )

  const selectedLeads: Array<number> = useSelector(
    (state: RootState) => state.leads.leads_list
  )
    .filter((item: ILead) => item.is_selected)
    .map((item: ILead) => item.id)

  const [isOpen, setIsOpen] = React.useState(false)
  const [isAddNewOpen, setIsAddNewOpen] = React.useState(false)
  const [input, setInput] = React.useState('')
  const [selectedList, setSelectedList] = React.useState<null | number>(null)

  const handleSelectClick = () => {
    setIsOpen(!isOpen)
    if (isOpen) {
      setIsAddNewOpen(false)
      setInput('')
    }
  }

  const handleItemClick = (id: number) => {
    setSelectedList(id)
  }

  const handlePlusClick = () => {
    setIsAddNewOpen(true)
  }

  const handleSave = () => {
    dispatch(saveCustomList(input.trim()))
    setInput('')
  }

  const handleCancelClick = () => {
    setIsAddNewOpen(false)
  }

  const handleMove = () => {
    if (selectedList) {
      setIsOpen(false)
      dispatch(moveLeadsToCustomAction(selectedList, selectedLeads))
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setInput(event.target.value)
  }

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={styles.wrapper}>
        <div className={styles.select} onClick={handleSelectClick}>
          <ArrowSvg rotate={isOpen} />
        </div>

        {isOpen && (
          <div className={styles.more}>
            <div className={styles.more_items}>
              {lists.length ? (
                lists.map(({ name, id }) => (
                  <SelectItem
                    isActive={selectedList === id}
                    key={id}
                    value={id}
                    onClick={handleItemClick}
                  >
                    {name}
                  </SelectItem>
                ))
              ) : (
                <div className={styles.empty}>
                  You don't have any custom list
                </div>
              )}
            </div>
            {isAddNewOpen && (
              <div className={styles.add_new}>
                <TextField
                  size="small"
                  autoFocus={true}
                  placeholder="New list"
                  value={input}
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </div>
            )}
            <div className={styles.more_footer}>
              <Button
                disabled={selectedList === null || selectedLeads.length === 0}
                onClick={handleMove}
              >
                Copy
              </Button>
              {isAddNewOpen ? (
                <div color="primary" className={styles.new_controls}>
                  <Button
                    onClick={handleSave}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={input.length === 0}
                  >
                    Save
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <Button onClick={handlePlusClick}>
                  <PlusSvg size={24} fill="var(--regular-color)" />
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default CustomListDropDown

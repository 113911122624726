// token/
import { API_BASE, API_VERSION, CLIENT_ID, CLIENT_SECRET } from './constants'
import { ILeadFormValues, ILeadStatus, ILoginForm } from '../globalTypes'
import { vesselSubmitPrettier } from './api.helper'

const postRequest = async (url: string, data: object) => {
  const full_url = url === 'token/' ? url : `${API_VERSION}crm/${url}`

  const response = await fetch(`${API_BASE}${full_url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  })
  const json = await response.json()
  json.ok = response.ok

  return await json
}

export const postAuthRequest = async (url: string, data: object) => {
  const token = JSON.parse(localStorage.getItem('access_token') || 'null')

  const response = await fetch(`${API_BASE}${API_VERSION}crm/${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })

  const json = await response.json()
  json.ok = response.ok

  return await json
}

export const userLoginRequest = (data: ILoginForm) => {
  return postRequest(`token/`, {
    grant_type: 'password',
    username: data.email,
    password: data.password,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    scope: 'stuff',
  })
}

export const postCustomList = (name: string) => {
  return postAuthRequest(`custom-lists/`, { name })
}

export const postNewLead = (lead: ILeadFormValues) => {
  return postAuthRequest(`leads/`, vesselSubmitPrettier(lead))
}

export const postNewStatusRequest = (status: ILeadStatus) => {
  return postAuthRequest(`statuses/`, status)
}

export const postSetStatuses = (status: number | null, ids: Array<number>) => {
  return postAuthRequest(`set-statuses/`, {
    status_id: status,
    lead_ids: ids,
  })
}
